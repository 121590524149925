<template>
<div class="container" style="max-width: 55%; margin-top: 180px; margin-bottom: 180px;">
    <h4 class="display-4"><b>Cek Kuota XL Sidompul</b></h4>
    <p class="lead">Digunakan untuk cek kuota XL kamu. Masukkan nomor Telp kamu di bawah ini!</p>
    <div class="form-group">
        <label for="msisdn">Msisdn XL:</label>
        <input type="text" class="form-control" name="msisdn" id="msisdn" v-model="msisdnValue" @input="handleInput" @keyup.enter="getData" placeholder="Msisdn (ex: 628xxx)" required>
    </div><br>
    <button class="btn btn-primary" @click="getData"><i class="fa fa-sign-in" aria-hidden="true"></i> Cek</button>

    <div style="text-align: left;">
        <br>
        <div id="resultError" style="color: red; font-weight: bold;" v-html="resultErrorValue"></div>
        <div id="result" v-html="resultValue"></div>
    </div>
  </div>

  <div id="cover-spin" style="display: block;" v-if="isShowSpinner"></div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';

export default {
    data(){
        return {
            data: [],
            isShowSpinner: false,
            msisdnValue: '',
            resultValue: '',
            resultErrorValue: ''
        };
    },
    async mounted() {
        try {
            //const response = await axios.get('https://jsonplaceholder.typicode.com/users');
            //this.data = response.data;
        } catch(error){
            //console.log('error fetching data: ', error);
        }
    },
    name: 'AppHome',
    props: {
        msg: String
    },
    methods: {
    testAlert() {
      //console.log("A");
      //alert("Eue");
    },
    handleInput(event) {
      // Remove non-numeric characters
      this.msisdnValue = event.target.value.replace(/\D/g, '');
    },
    async getData() {
        this.isShowSpinner = true;
        try {
            const reqHeaders = {
                paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
                headers: {
                    'Authorization': 'Basic c2lkb21wdWxhcGk6YXBpZ3drbXNw',
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Api-Key': '6fb99971-2a2f-40ed-815d-a64df9f3c975',
                    'X-App-Version': '1.0.0'
                }
            };

            const response = await axios.get('https://apigw.kmsp-store.com/sidompul/v1/cek_kuota?msisdn=' + this.msisdnValue + '&isJSON=true', reqHeaders);
            this.data = response.data;

            if(response.data.status == false){
                this.resultErrorValue = response.data.data.keteranganError;
                this.resultValue = "";
            }

            if(response.data.status == true){
                this.resultErrorValue = "";
                this.resultValue = response.data.data.hasil;
            }
            
        } catch(error){
            //console.log('error fetching data: ', error);
        } finally {
            this.isShowSpinner = false;
            /*setTimeout(() => {
            
            }, 1000);*/
        }
    }
  }
}
</script>

<style>
#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}
</style>